import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Vcluster } from "@netmedi/vcluster-types";
import ClipboardCopy from "./VclusterCopyText";

type VclusterFormProps = {
  vcluster: Vcluster;
  connectVcluster: (name: string) => void;
  kubeConfig: string;
};

export default function VclusterModal({
  vcluster,
  connectVcluster,
  kubeConfig,
}: VclusterFormProps) {
  const [show, setShow] = useState(false);
  // TODO: Tslint doing stupid shit and not shutting up when told to???
  /* tslint:disable-next-line */
  // const [_isSaved, setIsSaved] = React.useState<Boolean>(false);
  const stupidHackStfuTslintThisisGoodCodeNow = React.useState<Boolean>(false);
  const setIsSaved = stupidHackStfuTslintThisisGoodCodeNow[1];
  const [errorMessage, setErrorMessage] = React.useState<
    { message: string } | string | undefined
  >();

  const reset = () => {
    setIsSaved(true);
    setErrorMessage(errorMessage);
  };
  const handleClose = () => {
    reset();
    setShow(false);
  };
  const handleShow = (e: React.MouseEvent) => {
    connectVcluster(vcluster.name);
    e.preventDefault();
    setShow(true);
  };

  return (
    <>
      <Link
        data-testid="edit-button"
        to={`/vcluster/${vcluster.name}`}
        onClick={handleShow}
        className="btn btn-outline-primary"
      >
        Connect
      </Link>
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        animation={false}
        data-testid="site_setting_modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Usage instructions
          </Modal.Title>
        </Modal.Header>
        <Modal.Header>
          1) Copy the Kubeconfig to your local machine
        </Modal.Header>
        <Modal.Body>
          Copy Kubeconfig from below(click Copy button) to your local machine
          with filename: {vcluster.name}.yml
          <br />
          <br />
          <ClipboardCopy copyText={kubeConfig} />
        </Modal.Body>
        <Modal.Header>
          2) Merge new vcluster context into existing kubecontext
        </Modal.Header>
        <Modal.Body>
          a) Check if you have existing kubeconfig present on your machine
          <br />
          ls ~/.kube/config
          <br />
          If you get No such file or directory - error. Create empty file -
          otherwise skip to b):
          <br />
          touch ~/.kube/config
          <br />
          b) Merge new vCluster config to existing kubeconfig
          <br />
          cp ~/.kube/config ~/.kube/config.bak && KUBECONFIG=~/.kube/config:./
          {vcluster.name}.yml kubectl config view --flatten &gt; /tmp/config &&
          mv /tmp/config ~/.kube/config && chmod go-r ~/.kube/config
        </Modal.Body>
        <Modal.Header>3) Set vCluster as your active kubecontext</Modal.Header>
        <Modal.Body>
          <br />
          * Set your vCluster as active kubecontext:
          <br />
          kubectl config use-context vcluster_{vcluster.name}_holvikaari_
          <br />
        </Modal.Body>
        <Modal.Header>
          4) Test that you are able to connect to the cluster:
        </Modal.Header>
        <Modal.Body>kubectl get namespace</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
