import { useState } from "react";

type vClusterProps = {
  copyText: string;
};

export default function ClipboardCopy({ copyText }: vClusterProps) {
  const [isCopied, setIsCopied] = useState(false);
  const regex =
    /(?=.+[A-Z]{2,})(?=.+[a-z]{2,})(?=.+[0-9]{1,})([A-Za-z0-9+\/==]{100,})/gm;

  const redactedText = (copyText: string) => {
    if (copyText) {
      let copyTextRedacted = copyText.replace(regex, "<****>");
      return copyTextRedacted;
    }
  };

  // This is the function we wrote earlier
  async function copyTextToClipboard(text: string | undefined) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text || "");
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div style={{ whiteSpace: "pre-line" }}>{redactedText(copyText)}</div>
      {/* Bind our handler function to the onClick button property */}
      <br></br>
      <button onClick={handleCopyClick}>
        <span>{isCopied ? "Copied!" : "Copy"}</span>
      </button>
    </div>
  );
}
