import { Vcluster } from "@netmedi/vcluster-types";
import React from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { handleError } from "../../utils/errorHandler";
import VClusterNameField from "./VClusterNameField";
type VclusterFormProps = {
  createVcluster: (newVcluster: Vcluster) => Promise<void>;
};

export default function VclusterForm({ createVcluster }: VclusterFormProps) {
  const [isSaved, setIsSaved] = React.useState<Boolean>(false);
  const [vCluster, setVCluster] = React.useState<Vcluster>({
    name: "",
    namespace: "",
    status: "",
  });
  const [errorMessage, setErrorMessage] = React.useState<
    { message: string } | string | undefined
  >();
  const [validationErrors, setValidationErrors] = React.useState<
    string[] | undefined
  >();

  async function saveVcluster(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    await createVcluster(vCluster)
      .then(() => {
        setIsSaved(true);
        setErrorMessage(undefined);
      })
      .catch((reason) => handleError(reason, setErrorMessage));
  }

  return (
    <Form className="mt-4">
      <div className="container">
        <div className="row">
          <div className="col mt-4 mb-4">
            <h2>Create new virtual cluster</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-2">Virtual cluster name</div>
          <div className="col-6">
            <VClusterNameField
              object={vCluster}
              setValidationErrors={setValidationErrors}
              setValue={(value: { name: string }) => {
                console.log({ ...vCluster, name: value.name });
                setVCluster({ ...vCluster, name: value.name });
              }}
            />
          </div>
          <div className="row">
            <div className="col-2"></div>
            <div className="col-6">
              {(validationErrors || []).map((validationError) => (
                <Form.Text
                  as="div"
                  className="text-danger m-1"
                  key={`validation_errors`}
                >
                  {validationError}
                </Form.Text>
              ))}
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar fixed-bottom navbar-light bg-light">
        <div className="container">
          <div className="me-auto mb-2 mb-lg-0">
            <Link to="/" className="btn btn-outline-secondary">
              Back
            </Link>
          </div>
          <div className="d-flex">
            <span className="navbar-text me-3">
              {isSaved ? (
                <span className="text-primary">
                  Your changes have been saved, Vcluster is created
                </span>
              ) : (
                <span className="text-muted"></span>
              )}
            </span>
            <button
              disabled={!validationErrors || validationErrors.length > 0}
              className="btn btn-primary"
              onClick={saveVcluster}
            >
              Save
            </button>
          </div>
          {errorMessage && (
            <div className="alert alert-danger">
              Error occurred while saving vcluster: '
              {typeof errorMessage == "object"
                ? errorMessage.message
                : errorMessage}
              '
            </div>
          )}
        </div>
      </nav>
    </Form>
  );
}
