import { Col, Form } from "react-bootstrap";
import { useInit } from "../../utils/hooks";

type TextFieldProps = {
  object: { name: string };
  /**
   * setter for validation errors displayed on higher level component
   */
  setValidationErrors: (errors: string[]) => void;
  /**
   * setter for value on higher level component
   */
  setValue: (value: { name: string }) => void;
};

export default function VClusterNameField({
  object,
  setValidationErrors,
  setValue,
}: TextFieldProps) {
  const validate = (value: string | null | undefined) => {
    console.log(value);
    if (!value) {
      setValidationErrors(["Name of the cluster is required"]);
    } else if (!/^[a-z-]+$/.test(value)) {
      setValidationErrors([
        "Invalid format for name of the virtual cluster. Only small letters and '-' are allowed.",
      ]);
    } else {
      setValidationErrors([]);
    }
  };
  useInit(validate, [object.name]);

  return (
    <Col sm={8} data-testid={`text_vcluster_field_${object.name}`}>
      <Form.Control
        type="textarea"
        value={object.name}
        onChange={(e) => {
          validate(e.target.value);
          setValue({ name: e.target.value });
        }}
      />
    </Col>
  );
}
