import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AuthService from "../services/authService";
import { handleError } from "./errorHandler";

const authService = new AuthService();

export const useAuthentication = (
  setErrorMessage: (reason: string | { message: string }) => void
) => {
  const [jwtToken, setJwtToken] = useState<string | undefined>();
  const [refreshAttempted, setRefreshAttempted] = useState<boolean>(false);

  const history = useHistory();
  const returnLocation = localStorage.getItem("loginReturnLocation");
  if (!jwtToken && !returnLocation)
    localStorage.setItem("loginReturnLocation", history.location.pathname);

  if (jwtToken && returnLocation)
    localStorage.removeItem("loginReturnLocation");

  const navigateToAuthenticationPage = useCallback(() => {
    authService
      .getAuthenticationLink()
      .then((authenticationLink: string) => {
        window.location.href = authenticationLink;
      })
      .catch((reason) => handleError(reason, setErrorMessage));
  }, [setErrorMessage]);

  const refreshJwtTokenWithRefreshToken = useCallback(() => {
    authService
      .refresh()
      .then((jwtToken: string) => {
        setJwtToken(jwtToken);
      })
      .catch((_reason) => {
        setRefreshAttempted(true);
      });
  }, [setJwtToken, setRefreshAttempted]);

  const queryParams = new URLSearchParams(useLocation().search);
  const code = queryParams.get("code");
  const isLoginRetry =
    !!queryParams.get("loginRetry") && queryParams.get("loginRetry") === "true";

  useEffect(() => {
    // if code is not in params and jwtToken has not been specified and refreshing has not been attempted
    // try to refresh token
    if (!code && !jwtToken && !refreshAttempted) {
      refreshJwtTokenWithRefreshToken();
    }
    // if code is not in params and jwtToken has not been specified, navigate login page
    if (!code && !jwtToken && !isLoginRetry && refreshAttempted) {
      navigateToAuthenticationPage();
    }
    // if code is in params and jwtToken has not been specified, login to microservice
    if (code && !jwtToken) {
      authService
        .login(code)
        .then((jwtToken: string) => {
          setRefreshAttempted(false);
          setJwtToken(jwtToken);
          history.replace(returnLocation ? returnLocation : "/", {
            search: undefined,
          });
        })
        .catch(async (reason) => {
          await handleError(reason, setErrorMessage);
          // Note: usually this is triggered by the code being already used
          // Thus, let's remove query parameters, if we haven't tried it yet
          if (!isLoginRetry) {
            history.replace({
              search: "loginRetry=true",
            });
          }
        });
    }
  }, [
    code,
    jwtToken,
    history,
    isLoginRetry,
    navigateToAuthenticationPage,
    setErrorMessage,
    returnLocation,
    refreshAttempted,
    refreshJwtTokenWithRefreshToken,
  ]);

  return jwtToken;
};
