import Bugsnag from "@bugsnag/js";

export const handleError = async (
  reason: Promise<string | { message: string }>,
  setErrorMessage: (reason: string | { message: string }) => void
) => {
  const awaitedReason = await reason;
  Bugsnag.notify(
    new Error(
      typeof awaitedReason == "object" ? awaitedReason.message : awaitedReason
    )
  );
  setErrorMessage(awaitedReason);
};
