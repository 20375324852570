import { get } from "./api";

export default class SwaggerApiService {
  constructor(private token: string) {}

  public async getSwaggerApi(): Promise<string> {
    return get("/swagger-api", {
      headers: { authorization: `Bearer ${this.token}` },
    });
  }
}
