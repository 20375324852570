import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import MainPage from "./MainPage";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

Bugsnag.start({
  apiKey:
    process.env.REACT_APP_BUGSNAG_API_TOKEN ||
    "deadbeefdeadbeefdeadbeefdeadbeef",
  enabledReleaseStages: ["production"],
  plugins: [new BugsnagPluginReact()],
});

const BugsnagErrorBoundary =
  Bugsnag.getPlugin("react")!.createErrorBoundary(React);

ReactDOM.render(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <BugsnagErrorBoundary>
    <React.StrictMode>
      <Router>
        <MainPage />
      </Router>
    </React.StrictMode>
  </BugsnagErrorBoundary>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
