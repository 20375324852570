import { useEffect, useState } from "react";

export const useInit = <T extends any[]>(
  callback: (...args: T) => void,
  args: T
) => {
  const [mounted, setMounted] = useState(false);

  const resetInit = () => setMounted(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      callback(...args);
    }
  }, [mounted, callback, args]);

  return [resetInit];
};
