import { get, post } from "./api";

export default class AuthService {
  public async getAuthenticationLink(): Promise<string> {
    return get("/auth/authenticationLink", {
      headers: { authorization: "" },
    });
  }

  public async login(code: string): Promise<string> {
    return post("/auth/login", { code: code }, {});
  }

  public async refresh(): Promise<string> {
    return post("/auth/refresh", {}, {});
  }
}
