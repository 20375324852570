import { Vcluster } from "@netmedi/vcluster-types";
import Fuse from "fuse.js";
import React, { useCallback, useEffect, useState } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import VclusterForm from "./components/Vclusters/VclusterForm";
import Vclusters from "./components/Vclusters/Vclusters";
import "./MainPage.css";
import SwaggerApiService from "./services/swaggerApiService";
import { VclusterApiService } from "./services/VclusterApiService";
import { handleError } from "./utils/errorHandler";
import { useAuthentication } from "./utils/loginHooks";

function MainPage() {
  enum VCLUSTER_ACTION_TYPE {
    Create = 1,
    Delete = 2,
    Sleep = 3,
    Resume = 4,
  }
  // State variables
  const [vclusters, setvClusters] = useState<Vcluster[]>();
  const [swaggerJSON, setSwaggerJSON] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = React.useState<
    { message: string } | string | undefined
  >();
  const [query, setQuery] = useState<string>("");
  const history = useHistory();
  const jwtToken = useAuthentication(setErrorMessage);
  const [kubeConfig, setKubeConfig] = useState<string>("");
  const isPreloading = true;

  // fetch vcluster data
  const fetchData = useCallback(async () => {
    if (jwtToken) {
      await new VclusterApiService(jwtToken)
        .getAllVclusters()
        .then((results) => {
          setvClusters(results);
        })
        .catch((reason) => handleError(reason, setErrorMessage));
    }
  }, [jwtToken]);

  useEffect(() => {
    fetchData();
    // fetch api
    const fetchSwaggerApi = async () => {
      if (jwtToken) {
        await new SwaggerApiService(jwtToken)
          .getSwaggerApi()
          .then((result) => setSwaggerJSON(result))
          .catch((reason) => handleError(reason, setErrorMessage));
      }
    };
    fetchSwaggerApi();
    setQuery("");
  }, [jwtToken, fetchData]);

  const fuse = new Fuse(vclusters || [], {
    keys: ["name"],
    includeScore: true,
    useExtendedSearch: true,
    threshold: 0.2,
  });

  const results = fuse.search(query || "");

  // Update vCluster status
  const updateVclusterStatus = (
    actionType: VCLUSTER_ACTION_TYPE,
    vcluster: Vcluster,
    preloading: Boolean
  ) => {
    switch (actionType) {
      case VCLUSTER_ACTION_TYPE.Sleep:
        vcluster.status = preloading ? "Pausing" : "Paused";
        return vcluster;
      case VCLUSTER_ACTION_TYPE.Resume:
        vcluster.status = preloading ? "Resuming" : "Running";
        return vcluster;
      case VCLUSTER_ACTION_TYPE.Delete:
        vcluster.status = "Deleting";
        return vcluster;
    }
  };

  // Update/Refresh all vClusters
  const updateVclusters = (actionType: VCLUSTER_ACTION_TYPE, name: string) => {
    if (vclusters) {
      const updateVclusters = vclusters.map((vcluster) => {
        if (vcluster.name.includes(name)) {
          updateVclusterStatus(actionType, vcluster, !isPreloading);
        }
        // otherwise return object as is
        return vcluster;
      });
      setvClusters(updateVclusters);
    }
  };

  // sleep api
  const sleepVcluster = async (vcluster: Vcluster) => {
    if (jwtToken) {
      updateVclusterStatus(VCLUSTER_ACTION_TYPE.Sleep, vcluster, isPreloading);
      const result = await new VclusterApiService(jwtToken)
        .sleepVcluster(vcluster)
        .then(() => {
          updateVclusters(VCLUSTER_ACTION_TYPE.Sleep, vcluster.name);
        })
        .catch((reason) => handleError(reason, setErrorMessage));
      return result;
    }
  };

  // resume api
  const resumeVcluster = async (vcluster: Vcluster) => {
    if (jwtToken) {
      updateVclusterStatus(VCLUSTER_ACTION_TYPE.Resume, vcluster, isPreloading);
      await new VclusterApiService(jwtToken)
        .resumeVcluster(vcluster)
        .then(() => {
          // Resuming force the creation of the pod and that can take few seconds
          // The vcluster resume command does not inform when the pod is running
          setTimeout(() => {
            updateVclusters(VCLUSTER_ACTION_TYPE.Resume, vcluster.name);
          }, 15000); // 15 seconds
        })
        .catch((reason) => handleError(reason, setErrorMessage));
    }
  };

  // delete api
  const deleteVcluster = async (vcluster: Vcluster) => {
    if (jwtToken) {
      await new VclusterApiService(jwtToken)
        .deleteVcluster(vcluster)
        .then(() => {
          updateVclusters(VCLUSTER_ACTION_TYPE.Delete, vcluster.name);
          fetchData();
        })
        .catch((reason) => handleError(reason, setErrorMessage));
    }
  };

  // connect api
  const connectVcluster = async (name: string) => {
    // Reset kubeconfig
    setKubeConfig("");
    if (jwtToken) {
      await new VclusterApiService(jwtToken)
        .connectVclusters(name)
        .then((result) => {
          setKubeConfig(result);
          // Connect forces the cluster to resume from sleep mode
          // Update status of the vcluster
          updateVclusters(VCLUSTER_ACTION_TYPE.Resume, name);
        })
        .catch((reason) => handleError(reason, setErrorMessage));
    }
  };

  const createVcluster = async (newVcluster: Vcluster) => {
    if (jwtToken) {
      await new VclusterApiService(jwtToken)
        .createVcluster(newVcluster)
        .then(() => {
          if (vclusters) {
            // Adding new vcluster
            vclusters.push({
              name: newVcluster.name + "-vcluster",
              namespace: "",
              status: "Running",
            });
          }
        })
        .catch((reason) => handleError(reason, setErrorMessage));
    }
  };

  return (
    <>
      <nav className="navbar sticky-top navbar-light bg-light">
        <div className="container">
          <span
            className="navbar-brand"
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/")}
          >
            Virtual Cluster Management - Kaiku Health
          </span>
          <span className="navbar-right">
            <span>
              <Link className="m-2" to="/api-docs">
                API
              </Link>
            </span>
          </span>
        </div>
      </nav>
      <div className="container">
        <Switch>
          <Route path="/api-docs">
            {!!swaggerJSON && <SwaggerUI spec={swaggerJSON} />}
          </Route>
          <Route path="/vcluster/new">
            <div className="MainPage" data-testid={`mainpage`}></div>
            <VclusterForm createVcluster={createVcluster}></VclusterForm>
          </Route>
          <Route path="/">
            <div className="MainPage" data-testid={`mainpage`}>
              {vclusters ? (
                <Vclusters
                  vclusters={vclusters}
                  sleepVcluster={sleepVcluster}
                  resumeVcluster={resumeVcluster}
                  deleteVcluster={deleteVcluster}
                  connectVcluster={connectVcluster}
                  kubeConfig={kubeConfig}
                  results={results}
                  query={query}
                />
              ) : (
                <span>
                  {errorMessage
                    ? `Error occurred while retrieving Vclusters: '${
                        typeof errorMessage == "object"
                          ? errorMessage.message
                          : errorMessage
                      }'`
                    : jwtToken
                    ? `Loading ...`
                    : "Authenticating"}
                </span>
              )}
            </div>
          </Route>
        </Switch>
      </div>
    </>
  );
}

export default MainPage;
