const baseHostname = process.env.REACT_APP_API_DOMAIN_NAME || "localhost:8000";
const defaults = {
  credentials: (baseHostname.startsWith("localhost")
    ? "include"
    : "same-origin") as RequestCredentials,
  headers: {
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
  },
};
let baseURL = "";

if (baseHostname.startsWith("localhost")) {
  baseURL = "http://" + baseHostname;
} else {
  baseURL = "https://" + baseHostname;
}

const request = async (url: string, options: any) => {
  const finalHeaders = { ...defaults.headers, ...options.headers };
  if (finalHeaders["authorization"] === "Bearer ") {
    delete finalHeaders["authorization"];
  }
  const finalOptions = Object.assign(defaults, options);
  finalOptions.headers = finalHeaders;
  return fetch(baseURL + url, finalOptions).then((response) => {
    if (!response.ok) {
      throw response.json();
    } else {
      return response
        .text()
        .then((text) =>
          text && text.length ? JSON.parse(text) : Promise.resolve({})
        );
    }
  });
};

export const get = async (url: string, options: any) => {
  return request(
    url,
    Object.assign({ method: "GET" }, options, { body: null })
  );
};

export const post = async (url: string, data: any, options: any) => {
  return request(
    url,
    Object.assign({ method: "POST" }, options, { body: JSON.stringify(data) })
  );
};

export const put = async (url: string, data: any, options: any) => {
  return post(url, data, Object.assign({ method: "PUT" }, options));
};

export const deleteReq = async (url: string, data: any, options: any) => {
  return request(
    url,
    Object.assign({ method: "DELETE" }, options, { body: JSON.stringify(data) })
  );
};
