import { Vcluster } from "@netmedi/vcluster-types";
import { Link } from "react-router-dom";
import VclusterModal from "./VclusterModal";

type VclusterRowProps = {
  vcluster: Vcluster;
  kubeConfig: string;
  sleepVcluster: (vcluster: Vcluster) => void;
  resumeVcluster: (vcluster: Vcluster) => void;
  deleteVcluster: (vcluster: Vcluster) => void;
  connectVcluster: (name: string) => void;
};

export default function VclusterRow({
  vcluster,
  kubeConfig,
  sleepVcluster,
  resumeVcluster,
  deleteVcluster,
  connectVcluster,
}: VclusterRowProps) {
  return (
    <>
      <td data-testid="name">{vcluster.name}</td>
      <td data-testid="status">{vcluster.status}</td>
      <td>
        <div className="gap-2">
          <VclusterModal
            vcluster={vcluster}
            connectVcluster={connectVcluster}
            kubeConfig={kubeConfig}
          ></VclusterModal>
          <Link
            data-testid="edit-button"
            to={`/`}
            onClick={() => sleepVcluster(vcluster)}
            className="btn btn-outline-primary"
          >
            Sleep
          </Link>
          <Link
            data-testid="edit-button"
            to={`/`}
            onClick={() => resumeVcluster(vcluster)}
            className="btn btn-outline-primary"
          >
            Resume
          </Link>
          <Link
            data-testid="edit-button"
            to={`/`}
            onClick={() => deleteVcluster(vcluster)}
            className="btn btn-outline-primary"
          >
            Delete
          </Link>
        </div>
      </td>
    </>
  );
}
