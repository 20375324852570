import { Vcluster } from "@netmedi/vcluster-types";
import { deleteReq, get, post, put } from "./api";

export default interface VClusterService {
  getAllVclusters(): Promise<Vcluster[]>;
  createVcluster(vcluster: Vcluster): Promise<void>;
  connectVclusters(name: string): Promise<string>;
  sleepVcluster(vcluster: Vcluster): Promise<void>;
}

export class VclusterApiService implements VClusterService {
  constructor(private token: string) {}

  public async sleepVcluster(vcluster: Vcluster): Promise<void> {
    return put("/vclusters/sleep", vcluster, {
      headers: { authorization: `Bearer ${this.token}` },
    });
  }

  public async resumeVcluster(vcluster: Vcluster): Promise<void> {
    return put("/vclusters/resume", vcluster, {
      headers: { authorization: `Bearer ${this.token}` },
    });
  }

  public async getAllVclusters(): Promise<Vcluster[]> {
    return get("/vclusters", {
      headers: { authorization: `Bearer ${this.token}` },
    });
  }

  public async createVcluster(vcluster: Vcluster): Promise<void> {
    return post("/vclusters", vcluster, {
      headers: { authorization: `Bearer ${this.token}` },
    });
  }

  public async deleteVcluster(vcluster: Vcluster): Promise<void> {
    return deleteReq("/vclusters", vcluster, {
      headers: { authorization: `Bearer ${this.token}` },
    });
  }

  public async connectVclusters(name: string): Promise<string> {
    return get("/vclusters/connect/" + name, {
      headers: { authorization: `Bearer ${this.token}` },
    });
  }
}
