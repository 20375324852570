import React from "react";
import { Vcluster } from "@netmedi/vcluster-types";
import { Link } from "react-router-dom";
import VclusterRow from "./VclusterRow";
import Fuse from "fuse.js";

type vClusterProps = {
  vclusters: Vcluster[];
  sleepVcluster: (vcluster: Vcluster) => void;
  resumeVcluster: (vcluster: Vcluster) => void;
  deleteVcluster: (vcluster: Vcluster) => void;
  connectVcluster: (name: string) => void;
  results: Fuse.FuseResult<Vcluster>[];
  kubeConfig: string;
  query: string;
};

export default function Vclusters({
  vclusters,
  sleepVcluster,
  resumeVcluster,
  deleteVcluster,
  connectVcluster,
  kubeConfig,
  results,
  query,
}: vClusterProps) {
  return (
    <div className="container">
      <div className="row">
        <div className="col mt-4">
          <h2>Virtual clusters</h2>
        </div>
        <div className="col mt-4">
          <Link
            data-testid="create"
            type="button"
            to={"/vcluster/new"}
            className="btn btn-outline-primary"
          >
            Create new cluster
          </Link>
        </div>
      </div>
      {(query && !results.length) ||
        (vclusters && !vclusters.length ? (
          "No virtual clusters found!"
        ) : (
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {query
                ? results.map((vcluster) => (
                    <tr
                      key={vcluster.item.name}
                      data-testid={`vcluster-row-${vcluster.item.name}`}
                    >
                      <VclusterRow
                        vcluster={vcluster.item}
                        kubeConfig={kubeConfig}
                        sleepVcluster={sleepVcluster}
                        resumeVcluster={resumeVcluster}
                        deleteVcluster={deleteVcluster}
                        connectVcluster={connectVcluster}
                      />
                    </tr>
                  ))
                : vclusters
                    .sort((vcluster1, vcluster2) =>
                      vcluster1.name.localeCompare(vcluster2.name)
                    )
                    .map((vcluster) => (
                      <tr
                        key={vcluster.name}
                        data-testid={`vcluster-row-${vcluster.name}`}
                      >
                        <VclusterRow
                          vcluster={vcluster}
                          kubeConfig={kubeConfig}
                          sleepVcluster={sleepVcluster}
                          resumeVcluster={resumeVcluster}
                          deleteVcluster={deleteVcluster}
                          connectVcluster={connectVcluster}
                        />
                      </tr>
                    ))}
            </tbody>
          </table>
        ))}
    </div>
  );
}
